* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  min-height: 100vh;
}

body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  font-size: 14px;
  line-height: 1.2rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.app-main-blank {
  font-family: "Roboto", sans-serif;
  background-color: #ffffff;
  min-width: 100vw;
  min-height: 100vh;
}

.app-main {
  font-family: "Roboto", sans-serif;
  background-color: rgb(245, 245, 245);
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none !important;
  color: #000000;
}

span {
  text-transform: none !important;
}

.menu-item-wrapper {
  &:first-child {
    margin-left: -8px !important;
  }
}

body::-webkit-scrollbar {
  width: 12px;
}

.channel-list-div::-webkit-scrollbar {
  width: 4px;
}

.channel-list-div::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 16px;
}

// wave pattern
.custom-shape-divider-bottom-1634380490 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1634380490 svg {
  position: relative;
  display: block;
  width: calc(128% + 1.3px);
  height: 170px;
}

.custom-shape-divider-bottom-1634380490 .shape-fill {
  fill: #050f66;
}


.json-to-excel-download-button {
 padding: 4px 4px !important;
 font-weight: 500 !important;
 border-radius: 4px !important;
 cursor: pointer;
}


.MuiInputBase-root .MuiInputBase-filled {
  background-color:#E8F0FE;
}